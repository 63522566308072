export enum FormStatus {
  Valid = 'VALID',
  Invalid = 'INVALID',
}

export const ONLY_TWO_TYPING_VALIDATOR_KEY = 'only_two_typing';
export const COOP_DONOR_GRID_VALIDATION_KEY = 'gridOrRegistryId';
export const GRID_VALIDATION_MESSAGE = {
  ERR_LENGTH: 'COOP_SEARCHES.VALIDATION.INVALID_LENGTH',
  ERR_CHECKSUM: 'COOP_SEARCHES.VALIDATION.CHECKSUM',
  ERR_FIRST_CHAR_ZERO: 'COOP_SEARCHES.VALIDATION.FIRST_CHAR_ZERO',
  ERR_FIRST_FOUR_CHARS_NON_NUMERIC: 'COOP_SEARCHES.VALIDATION.FIRST_FOUR_CHARS_NON_NUMERIC',
  ERR_CHECKSUM_NON_NUMERIC: 'COOP_SEARCHES.VALIDATION.CHECKSUM_NON_NUMERIC',
  ERR_NON_UNIQUE_IND: 'COOP_SEARCHES.VALIDATION.ERR_NON_UNIQUE_IND',
};
